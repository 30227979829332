<template>
  <div>
        <kendo-grid
            ref="grid"
            :data-source="SalesReport"
            :filterable="true"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
        >
        </kendo-grid>
        <sales-report-pdf ref = "salesReportPDF"/>
  </div>
</template>


<script>
import {globalfunc} from '../../../../shared/GlobalFunction';
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { kendo_grid, datetime_zone } from '../../../../infrastructure/constant/variable';
import moment from 'moment';

import salesReportServices from '../Script/SalesReportService.js';
import salesReportPdf from '../../../../assets/template/SalesReportPdfTemplate.vue'

export default {
    name: 'SalesReportGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'sales-report-pdf': salesReportPdf
    },
    props: ['dateTo','dateFrom','typeDetail', 'itemId','status','area','customer','sales','projectType'],
    computed: {
        SalesReport () {
            var dateTo = this.$props.dateTo;
            var dateFrom = this.$props.dateFrom;
            var typeDetail = this.$props.typeDetail;
            var itemId = this.$props.itemId;
            var status = this.$props.status;
            var area = this.$props.area;
            var customer = this.$props.customer;
            var sales = this.$props.sales;
            var projectType = this.$props.projectType;

            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                startDate: dateFrom + datetime_zone.date_from,
                                endDate: dateTo + datetime_zone.date_to,
                                typeDetail: typeDetail == null ? null : typeDetail.value,
                                itemId: itemId == null ? null : itemId.value,
                                status: status == null ? null : status.value,
                                areaCode: area == null ? null : area.value,
                                customer: customer == null ? null : customer.value,
                                sales: sales == null ? null : sales.value,
                                projectType: projectType == null ? null : projectType.value,
                            };
                            return {
                                query: salesReportServices.readSalesReport(),
                                variables:variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetReportingSales.detail == null){
                            return [];
                        }
                        else{
                            return response.data.GetReportingSales.detail;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetReportingSales.detail == null){
                            return 0;
                        }
                        else{
                            return response.data.GetReportingSales.detail.length;
                        }
                    },
                },
            })
        }
    },
    data: function () {
        return  {
            columns:  [
                { filterable: false, filterable: false, field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} ,},
                { filterable: false, field: "purchase_order_number", width: 200, title: "No. PO", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { filterable: false, field: "sp_number", title: "No. SP", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { filterable: false, field: "areaname", width: 200, title: "Area", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { filterable: false, field: "customer_name", title: "Nama Customer", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { filterable: false, field: "project_type", title: "Tipe", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { filterable: false, field: "project_name", title: "Nama Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { filterable: false, field: "payment_method", title: "Metode Pembayaran", width: 200,headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { filterable: false, field: "sales_name", title: "Sales", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { filterable: false, field: "invoice_number", title: "No. Invoice", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: "product_code", title: "Kode Barang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "group_type_name", title: "Tipe Barang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "item_name", title: "Nama Barang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { filterable: false, field: "uom", title: "Satuan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { filterable: false, field: "quantity", title: "Jumlah", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { filterable: false, field: "price_discount", title: "Total", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}, format:"{0:N0}" },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator
            },
            pageableConfig: kendo_grid.default_pageable_config,
            sortableConfig: {
                mode: "multiple"
            },
        };
    },
    mounted: async function() {
        
    },
    methods: {
        columnFile(e){
            return this.$globalfunc.gridFileViewButton(e.attachment);
        },
        async exportExcel (){
            var dateTo = this.$props.dateTo;
            var dateFrom = this.$props.dateFrom;
            var typeDetail = this.$props.typeDetail;
            var itemId = this.$props.itemId;
            var status = this.$props.status;
            var area = this.$props.area;
            var customer = this.$props.customer;
            var sales = this.$props.sales;
            var projectType = this.$props.projectType;
            
            var variables = {
                startDate: dateFrom + datetime_zone.date_from,
                endDate: dateTo + datetime_zone.date_to,
                typeDetail: typeDetail == null ? null : typeDetail.value,
                itemId: itemId == null ? null : itemId.value,
                status: status == null ? null : status.value,
                areaCode: area == null ? null : area.value,
                customer: customer == null ? null : customer.value,
                sales: sales == null ? null : sales.value,
                projectType: projectType == null ? null : projectType.value,
            };
            var dataItem = await salesReportServices.getSalesReport(variables);

            var data = await salesReportServices.salesReportExcel(dataItem.detail, variables);
            
            if(dataItem.detail == null) {
                this.$swal("Error", response.noRecord, "error")
            }
            else{
                var XLSX = require("xlsx");
                var dateNow = moment(new Date()).format("YYYY-MM-DD-HHmmss");

                var filename = 'Report_Sales_' + dateNow + '.xlsx';
                var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
                ws['!cols'] = salesReportServices.salesReportExcelHeaderSize();
                
                var wb = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(wb, ws);
                XLSX.writeFile(wb, filename); 
            }
        },
        generatePDF(){
            var gridData = this.$refs.grid.kendoWidget()._data;
            this.$refs.salesReportPDF.generatePDF(gridData);
        }
    },  
}
</script>